<template>
  <v-app>
    <v-banner
        single-line
        height="400"
        class="banner"
    >
      <v-img
          src="https://h5.ophyer.cn/official_website/banner/bannerShortMessage400.jpg"
          height="100%"
          :transition="false"
          @load="handleLoad"
          :class="imgLoad ? 'v-image__image--animation':''">
        <template v-slot:placeholder>
          <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
          >
            <v-progress-circular
                indeterminate
                size="80"
                width="8"
                color="grey lighten-3"
            ></v-progress-circular>
          </v-row>
        </template>
        <v-container color="transparent" class="pa-0 max-width-1200 height-per-100 d-flex align-center">
          <v-card color="transparent ml-13" flat>
            <v-card-title class="pa-0 font-size-banner-title family-Bold">
              <span class="color-FFF">短信服务</span>
            </v-card-title>
            <v-card-title class="pa-0 font-size-banner-tip mt-7">
              <span class="color-FFF">触达全球的短信服务，支持国内短信与国际/港澳台短信</span>
            </v-card-title>
            <v-card-actions class="pa-0 mt-11">
              <v-btn
                  rounded
                  width="160"
                  height="50"
                  color="#FFFFFF"
                  class="font-size-20"
                  target="_blank"
                  href="http://wpa.qq.com/msgrd?v=3&uin=3138821165&site=qq&menu=yes"
              >
                <span style="color: #2672FF">申请开通</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-container>
      </v-img>
    </v-banner>
    <div class="d-flex justify-center" style="padding-bottom: 74px;">
      <v-container class="ma-0 pa-0 max-width-1200">
        <v-row class="justify-center pa-0 public-title" style="margin-top: 80px;">
          <p>PRODUCT FEATURES</p>
          <div>
            <span>产品功能</span>
          </div>
        </v-row>
        <v-row class="ma-0 mt-16">
          <v-col cols="3" class="pa-0" v-for="(info,index) in funList" :key="index">
            <v-card flat>
              <v-img class="margin-l-center"
                     width="120" height="120" contain
                     :src="info.src"
                     style="border-radius: 60px;box-shadow: 0px 15px 20px 0px rgba(0,114,255,0.4)"></v-img>
              <v-card-title class="justify-center mt-7 pa-0 font-weight-bold font-size-24">{{ info.title }}</v-card-title>
              <v-card-text class="text-align-center pa-0 mt-5 font-size-14">{{ info.content }}</v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div style="background-color: #FAFAFA;">
      <div class="scope-service" style="width:1200px; margin:0 auto;">
        <v-container class="mt-13 pa-0 max-width-1200" >
          <v-row class="public-title justify-center mt-0 pa-0">
            <p>SCOPE OF SERVICES</p>
            <div>
              <span>服务范围</span>
              <span class="mt-6 font-size-14">支持快速灵活地介入国内外高质量文本短信服务，服务范围覆盖200+国家/地区，即时触达全球各地</span>
            </div>
          </v-row>
          <v-row class="justify-center ma-0 mt-8">
            <v-img width="100%" height="400" src="https://h5.ophyer.cn/official_website/other/smsMap01.png" contain>
              <template v-slot:placeholder>
                <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                >
                  <v-progress-circular
                      indeterminate
                      size="80"
                      width="8"
                      color="grey lighten-3"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-row>
        </v-container>
      </div>
    </div>

    <div class="product-advantages" style="width:1200px; margin:0 auto;">
      <v-container class="max-width-1200" style="margin-top: 80px;">
        <v-row class="public-title justify-center pa-0 mt-0">
          <p>PRODUCT ADVANTAGES</p>
          <div>
            <span>产品优势</span>
          </div>
        </v-row>
        <v-row class="mt-15">
          <v-col cols="3" class="pa-0 d-flex justify-center" v-for="(info,index) in advantageList" :key="index">
            <v-card flat class="" width="255">
              <v-img class="margin-l-center" width="120" height="120" contain :src="info.src"></v-img>
              <v-card-title class="justify-center mt-5 pa-0 font-size-24">{{ info.title }}</v-card-title>
              <v-card-text class="pa-0 mt-5 font-size-14 text-align-center">{{ info.content }}</v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div style="background-color: #FAFAFA;">
      <div class="app-scenes" style="width:1200px; margin:0 auto; margin-top: 60px;">
        <v-container class="max-width-1200" style="margin-top: 60px;">
          <v-row class="public-title justify-center pa-0 mt-0">
            <p>APPLICATION SCENARIO</p>
            <div>
              <span>应用场景</span>
            </div>
          </v-row>
          <v-row class="mt-0">
            <v-tabs
                v-model="tab"
                align-with-title
                background-color="transparent"
                color="#0084FF"
                class="scenes-tab"
            >
              <v-tab
                  v-for="(item,index) in appScenes"
                  :key="index"
                  class="pa-0 align-start min-width-0 font-size-24 mx-16"
              >
                {{ item.tabName }}
              </v-tab>
            </v-tabs>
          </v-row>
          <v-row class="mt-0">
            <v-tabs-items v-model="tab" class="width-per-100 transparent mt-10">
              <v-tab-item
                  v-for="(item,index) in appScenes"
                  :key="index"
                  transition="fade-transition"
              >
                <v-card flat color="transparent" class="d-flex justify-center">
                  <transition name="slide-fade" appear>
                    <v-img :src="item.src" width="400" height="260" max-width="500" style="border-radius: 30px;" v-show="tab === index">
                      <template v-slot:placeholder>
                        <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                        >
                          <v-progress-circular
                              indeterminate
                              size="80"
                              width="8"
                              color="grey lighten-3"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </transition>
                  <transition name="slide-fade-reverse" appear>
                    <div style="margin-left: 80px;min-width: 400px;" v-show="tab === index" class="d-flex align-center">
                      <div>
                        <div v-for="(info,index) in item.infos" :key="index">
                          <v-card-title class="pa-0 font-size-24 font-weight-bold" :class="index > 0 ? 'mt-6':''">{{info.title}}</v-card-title>
                          <v-card-text class="pa-0 mt-4 font-size-14 line-height-1">
                            <ul class="pl-0">
                              <li v-for="(content,index) in info.contents" :key="index" class="mb-3 color-666">{{content}}</li>
                            </ul>
                          </v-card-text>
                        </div>
                      </div>
                    </div>
                  </transition>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-row>
        </v-container>
      </div>
    </div>

  </v-app>
</template>

<script>
let _this;
export default {
  name: "ShortMessage",
  data() {
    return {
      bannerLoad: false,
      imgLoad: false,
      // windowHight: window.innerHeight/2,
      tab: 0,
      funList: [
        {
          src: require("../../../public/img/icons/shortMessage/fun01.png"),
          title:"个性化短信",
          content:"结合企业需求，支持个性化短信内容"
        },
        {
          src: require("../../../public/img/icons/shortMessage/fun02.png"),
          title:"定时发送",
          content:"设置发送时间，定时推送消息"
        },
        {
          src: require("../../../public/img/icons/shortMessage/fun03.png"),
          title:"多元化接入",
          content:"支持多场景，WEB页面，API等方式接入"
        },
        {
          src: require("../../../public/img/icons/shortMessage/fun04.png"),
          title:"数据统计",
          content:"在线实时查询，监控短信发送情况"
        }
      ],
      appScenes: [
        {
          tabName: "验证码短信",
          src: "https://h5.ophyer.cn/official_website/other/sms01.png",
          infos: [
            {
              title: "使用场景",
              contents: ["• 用户注册","• 安全登录","• 找回密码","• 手机绑定","• 身份验证","• 支付验证"]
            }
          ]
        },
        {
          tabName: "通知短信",
          src: "https://h5.ophyer.cn/official_website/other/sms02.png",
          infos: [
            {
              title: "使用场景",
              contents: ["• 系统升级或维护","• 服务开通","• 账户登录、异常登录","• 价格调整、订单确认","• 物流动态","• 消费确认、支付通知等"]
            }
          ]
        },
        {
          tabName: "营销短信",
          src: "https://h5.ophyer.cn/official_website/other/sms03.png",
          infos: [
            {
              title: "使用场景",
              contents: ["• 生日祝福、节日问题","• 活动邀请","• 新品上线、产品促销、折扣信息、返利等"]
            },
            {
              title: "适用用户",
              contents: ["• 已完成企业认证的腾讯云账户"]
            }
          ]
        }
      ],
      advantageList: [
        {
          src: require("../../../public/img/icons/shortMessage/adv01.png"),
          title: "触达全球",
          content: "可支持全球 200+ 国家/地区的文本短信发送，覆盖全球 1000+ 运营商，即时触达全球各地"
        },
        {
          src: require("../../../public/img/icons/shortMessage/adv02.png"),
          title: "快速稳定",
          content: "国内短信到达率高达 99%（错号、空号或不在服务区除外）；超低延时，90% 以上短信10s内触达"
        },
        {
          src: require("../../../public/img/icons/shortMessage/adv03.png"),
          title: "接入便捷",
          content: "支持 HTTPS、CMPP、SMPP等标准协议，灵活提供多种语言SDK，最快10分钟接入，1小时上线"
        },
        {
          src: require("../../../public/img/icons/shortMessage/adv04.png"),
          title: "智能调度",
          content: "国内三网 100% 覆盖，实时监控自动切换；支持大容量，高并发处理，确保短信高速发送"
        }
      ]
    };
  },
  mounted(){
    _this = this;
    this.bannerLoad = true;
  },
  methods: {
    handleLoad: ()=>{
      _this.imgLoad = true;
      console.log("ewf")
    }
  }
}
</script>

<style lang="scss" scoped>
.banner{
  position: relative;
  ::v-deep .v-banner__wrapper,::v-deep .v-banner__content,::v-deep .v-banner__text{
    padding: 0 !important;
    height: 100%;
  }
}
::v-deep .public-title{
  height: unset;
  margin-bottom: unset;
  p {
    height: 40px;
    line-height: 40px;
    margin-bottom: 0;
  }
}
.scope-service{
  background-color: #FAFAFA;
  height: 580px;
  display: flex;
  justify-content: center;
}
.product-advantages{
  height: 520px;
  display: flex;
  justify-content: center;
}
.app-scenes{
  height: 580px;
  background-color: #FAFAFA;
  display: flex;
  justify-content: center;
  ::v-deep .v-tabs-bar__content{
    display: flex;
    justify-content: center;
  }
  ::v-deep .scenes-tab{
    margin-top: 50px;
    .v-tabs-bar{
      height: 50px;
    }
    .v-tabs-slider-wrapper{
      top: 46px;
      display: flex;
      justify-content: center;
      .v-tabs-slider{
        width: 46px !important;
        height: 4px !important;
      }
    }
  }
}
.cooperation-partner{
  margin-top: 110px;
  display: flex;
  justify-content: center;
}
</style>